<template>
  <div class="download">
    <h2 class="title">Nos modèles de document à télécharger gratuitement</h2>

    <div class="cards">
      <a
        v-for="(card, index) in cards"
        :key="index"
        class="card link"
        :href="card.link"
        @click="trackDownload(card.title)"
      >
        <img
          class="tick-icon"
          width="60"
          height="56"
          :src="card.img"
          :alt="card.title"
          loading="lazy"
        />
        <div class="text">
          <h3 class="title">{{ card.title }}</h3>
          <span>Télécharger le document</span>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cards: [
        {
          title: 'Bail de location',
          link: `${this.$config.public.hpBaseUrl}/ressources/bail-de-location-modele`,
          img: '/imgs/pages/gestion/bail.webp',
        },
        {
          title: 'Quittance de loyer',
          link: `${this.$config.public.hpBaseUrl}/ressources/quittance-de-loyer-modele`,
          img: '/imgs/pages/gestion/quittance.webp',
        },
        {
          title: 'État des lieux d’entrée',
          link: `${this.$config.public.hpBaseUrl}/ressources/etat-des-lieux-entree-modele`,
          img: '/imgs/pages/gestion/etat-entree.webp',
        },
        {
          title: 'État des lieux de sortie',
          link: `${this.$config.public.hpBaseUrl}/ressources/etat-des-lieux-de-sortie-modele`,
          img: '/imgs/pages/gestion/etat-sortie.webp',
        },
      ],
    }
  },
  methods: {
    trackDownload(title) {
      useTracking().track('[GESTION_LP] Clic Télécharger Modèle Document', { typeDocument: title })
    },
  },
}
</script>

<style lang="less" scoped>
.download {
  .app-padding();

  padding-top: 50px;
  padding-bottom: 50px;
  background-color: var(--ds-color-primary-25);
  & > .title {
    text-align: center;
    font-size: 24px;
    font-weight: var(--ds-weight-semi-bold);
    color: var(--ds-color-primary-100);
  }
  .cards {
    margin: 60px auto 0 auto;
    display: grid;
    justify-items: center;
    gap: 12px;
    width: fit-content;

    .link {
      text-decoration: none;
    }

    .card {
      flex: 50%;
      background: var(--ds-color-white-100);
      border: 1px solid #d5d7db;
      border-radius: 4px;
      padding: 32px;
      max-width: 413.5px;
      max-height: 120px;
      display: flex;
      height: 120px;
      gap: 32px;
      transition: all ease 0.2s;
      cursor: pointer;

      h3 {
        color: #272727;
        font-weight: 400;
      }

      span {
        font-size: 16px;

        font-weight: 500;
        color: var(--ds-color-primary-100);
      }

      .text {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        .title {
          font-size: 18px;
          margin: 0;
        }
      }
      &:hover {
        background-color: var(--ds-color-gray-25);
      }
    }
  }

  @media @bp-desktop {
    & > .title {
      font-size: 32px;
    }

    .cards {
      grid-template-columns: repeat(2, 1fr);
      .card {
        padding: 32px 65px;
      }
    }
  }
}
</style>
